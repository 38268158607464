<template>
  <a
    href="https://cert.gcb.cw/certificate?id=ZXlKcGRpSTZJbU5TYkdneGMwbHhkMkZJWVdGeU9VeG5NMjlrZG1jOVBTSXNJblpoYkhWbElqb2lTM1paTmxCVmFFUkNTRUo1ZGl0YVVIRnplSEYyZHowOUlpd2liV0ZqSWpvaU5tUXlZMkl4TUdKaU56VTFOR013TmprNU9XUm1OREV6TWpabU1tRm1NR0UwTnpRNFlqUXdaamd4WVdFNVptUTRZVGs1TWpnM01UTmlObVE0WW1KaE5pSXNJblJoWnlJNklpSjk="
    target="_blank"
  >
    <img
      src="../images/GCB_Seal.svg"
      loading="lazy"
      :width="imageSize.width"
      :height="imageSize.height"
      alt="License"
      class="licence"
    />
  </a>
</template>

<script setup lang="ts">
const { size = 'l' } = defineProps<{
  size?: 's' | 'l'
}>()

const imageSize = computed(() =>
  size === 's'
    ? {
        width: 99,
        height: 56,
      }
    : {
        width: 112,
        height: 64,
      },
)
</script>

<style scoped>
.licence {
  border-radius: var(--border-radius-150);
}
</style>
