import type { IconName } from '@st/ui/components/StIcon/types'

interface FooterItem {
  title: string
  children: {
    title: string
    link: string
    linkMobile: string
    isNewWindow?: boolean
    icon?: IconName
  }[]
}

interface SocialMediaItem {
  path: string
  icon: IconName
}
export interface UseFooterReturn {
  socialMedia: SocialMediaItem[]
  footerItems: Ref<FooterItem[]>
  copyright: Ref<string>
  isVisibleLicenceValidator: Ref<boolean>
}

export function useFooter(): UseFooterReturn {
  const { t } = useI18n()
  const { mainCategories } = storeToRefs(useCasinoStore())
  const { supportUrl } = useSupportUrl()
  const {
    copyrightType,
    isVisibleFooterInformationSection,
    isVisibleLicenceValidator,
  } = useDomainFeatures()

  const termsUrl = usePublicFileUrl('publicFileTerms')
  const privacyPolicyUrl = usePublicFileUrl('publicFilePrivacyPolicy')
  const amlPolicyUrl = usePublicFileUrl('publicFileAmlPolicy')
  const betTermsUrl = usePublicFileUrl('publicFileBetTerms')

  const responsibleGamblingUrl = usePublicFileUrl(
    'publicFileResponsibleGambling',
  )

  const socialMedia: SocialMediaItem[] = [
    { path: 'https://discord.gg/betone', icon: 'discord' },
    { path: 'https://x.com/bet_one_com', icon: 'twitter' },
    { path: 'https://t.me/betone_com', icon: 'telegram' },
  ]

  const popularCategories = computed(() =>
    mainCategories.value.map((category) => ({
      title: category.name || category.code,
      link: `/casino/categories/${category.code}`,
      linkMobile: `/mobile/casino/categories/${category.code}`,
    })),
  )

  const footerItems: ComputedRef<FooterItem[]> = computed(() => [
    {
      title: t('footer.casino'),
      children: [
        {
          title: t('footer.casinoMain'),
          link: '/casino',
          linkMobile: '/mobile/casino',
        },
        ...popularCategories.value,
        {
          title: t('footer.providers'),
          link: '/casino/providers',
          linkMobile: '/mobile/casino/providers',
        },
      ],
    },
    {
      title: t('footer.sport'),
      children: [
        {
          title: t('footer.sportMain'),
          link: '/sport',
          linkMobile: '/mobile/sport',
        },
        {
          title: t('footer.liveEvents'),
          link: '/sport/live',
          linkMobile: '/mobile/sport/live',
        },
        {
          title: t('footer.upcoming'),
          link: '/sport/upcoming',
          linkMobile: '/mobile/sport/upcoming',
        },
        {
          title: t('footer.allEvents'),
          link: '/sport/all',
          linkMobile: '/mobile/sport/all',
        },
      ],
    },
    {
      title: t('footer.aboutUs'),
      children: [
        {
          title: t('footer.supportService'),
          link: '/support',
          linkMobile: '/mobile/support',
        },
        {
          title: t('footer.help'),
          link: supportUrl.value ?? '',
          linkMobile: supportUrl.value ?? '',
          isNewWindow: true,
        },
      ],
    },
    ...(isVisibleFooterInformationSection.value
      ? [
          {
            title: t('footer.information'),
            children: [
              {
                title: t('footer.termsAndConditions'),
                link: termsUrl.value ?? '',
                linkMobile: termsUrl.value ?? '',
                isNewWindow: true,
              },
              {
                title: t('footer.privacyPolicy'),
                link: privacyPolicyUrl.value ?? '',
                linkMobile: privacyPolicyUrl.value ?? '',
                isNewWindow: true,
              },
              {
                title: t('footer.responsibleGaming'),
                link: responsibleGamblingUrl.value ?? '',
                linkMobile: responsibleGamblingUrl.value ?? '',
                isNewWindow: true,
              },
              {
                title: t('footer.amlPolicy'),
                link: amlPolicyUrl.value ?? '',
                linkMobile: amlPolicyUrl.value ?? '',
                isNewWindow: true,
              },
              {
                title: t('footer.betTerms'),
                link: betTermsUrl.value ?? '',
                linkMobile: betTermsUrl.value ?? '',
                isNewWindow: true,
              },
            ],
          },
        ]
      : []),
    {
      title: t('footer.betoneApp'),
      children: [
        {
          title: t('footer.telegram'),
          link: 'https://t.me/BetoneComBot',
          linkMobile: 'https://t.me/BetoneComBot',
          icon: 'telegram',
          isNewWindow: true,
        },
        {
          title: t('footer.android'),
          link: '/pwa',
          linkMobile: '/mobile/pwa',
          icon: 'android',
        },
        {
          title: t('footer.ios'),
          link: '/pwa',
          linkMobile: '/mobile/pwa',
          icon: 'apple',
        },
        {
          title: t('footer.windows'),
          link: '/pwa',
          linkMobile: '/mobile/pwa',
          icon: 'windows',
        },
        {
          title: t('footer.macos'),
          link: '/pwa',
          linkMobile: '/mobile/pwa',
          icon: 'apple',
        },
      ],
    },
  ])

  const url = useRequestURL()

  const copyright = computed(() => {
    const domain = url.hostname
    const year = new Date().getFullYear()

    return copyrightType.value === 'full'
      ? t('footer.copyright', { year, domain })
      : t('footer.copyrightWithoutDetails', { year, domain })
  })

  return {
    socialMedia,
    footerItems,
    copyright,
    isVisibleLicenceValidator,
  }
}
