import type { ToRefs } from 'vue'

type DomianType = 'mirror' | 'mainSite'

interface DomainConfig {
  isVisibleLicenceValidator: boolean
  copyrightType: 'short' | 'full'
  isVisibleFooterInformationSection: boolean
}

const domainConfig: Record<DomianType, DomainConfig> = {
  mirror: {
    isVisibleLicenceValidator: false,
    copyrightType: 'short',
    isVisibleFooterInformationSection: false,
  },
  mainSite: {
    isVisibleLicenceValidator: true,
    copyrightType: 'full',
    isVisibleFooterInformationSection: true,
  },
}

export function useDomainFeatures(): ToRefs<DomainConfig> {
  const url = useRequestURL()

  const domainType = computed<DomianType>(() => {
    const isDevOrStage = url.hostname.includes('xl.b')
    const isBetone = url.hostname === 'betone.com'
    const isLocal = process.env.NODE_ENV === 'development'
    const isStorybook = url.port === '6006'

    if (isBetone || isLocal || isDevOrStage || isStorybook) {
      return 'mainSite'
    }

    return 'mirror'
  })

  return toRefs(toReactive(computed(() => domainConfig[domainType.value])))
}
